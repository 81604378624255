import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "reactstrap"
import MySwiper from "../components/swiper/swiper"
import SampleForm from "../components/form/form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import "../components/form/Contact-Form-Clean.css"
import "../components/styles.css"
import "../components/swiper/Simple-Slider.css"
import BlockContent from "@sanity/block-content-to-react"

import logohero from "../images/logo-hero.png"
import banner1 from "../images/banner1.png"
import banner2 from "../images/banner2.png"
import banner3 from "../images/banner3.png"
import prodisplay from "../images/product-display.jpg"
import days from "../images/30d.png"
import freeship from "../images/freeship.png"
import support from "../images/support.png"
import blog1 from "../images/blog1.jpg"
import blog2 from "../images/blog2.jpg"
import blog3 from "../images/blog3.jpg"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost.edges
    const aboutPage = this.props.data.aboutPage
    const siteSettings = this.props.data.siteSettings
    console.log(siteSettings)

    const serializers = {
      types: {
        code: props => (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        ),
      },
    }

    console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <MySwiper slidesperview={1}>
          <div className="row justify-content-center">
            <div className="img-fluid banner-atf row banner1-size">
              <div className="col-md-5">
                <img className="banner-img img-fluid" src={banner1} />
              </div>
              <div className="col-md-6 mobile-banner">
                <h1 className="heading-hero text-purple ipad-heading">Let</h1>
                <img className="img-fluid logo-hero" src={logohero} />
                <h1 className="heading-subhero text-purple ipad-heading">
                  soothe away your
                </h1>
                <h1 className="heading-subhero2 text-purple ipad-heading">
                  aches and pains
                </h1>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="img-fluid banner-atf row banner2-size">
              <div className="col-md-5">
                <img className="banner-img img-fluid" src={banner2} />
              </div>
              <div className="col-md-6">
                <h1 className="heading-hero text-purple">
                  <strong />
                </h1>
                <h1 className="text-purple ipad-heading">
                  <strong>
                    Add a few drops of CBD to your life with delicious flacvored
                    tinctures from
                    <br />
                  </strong>
                </h1>
                <img className="img-fluid logo-hero" src={logohero} />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="img-fluid banner-atf row banner3-size">
              <div className="col-md-5">
                <img className="banner-img img-fluid" src={banner3} />
              </div>
              <div className="col-md-6">
                <h1 className="heading-hero text-purple">
                  <strong />
                </h1>
                <h1 className="text-purple ipad-heading">
                  <strong>Treat yourself with CBD gummy bears from</strong>
                </h1>
                <img className="img-fluid logo-hero" src={logohero} />
              </div>
            </div>
          </div>
        </MySwiper>
        <div className="our-products section">
          <div className="container">
            <div className="container">
              <div className="col">
                <h1 className="text-center text-purple">
                  <strong>OUR PRODUCTS</strong>
                </h1>
                <p className="text-center text-black-50 section-paragraph">
                  We infuse our edibles with a 100% hemp-based, proprietary
                  blend oil. Our oil is extracted using subcritical (low
                  temperature and pressure) CO2. This method is non-toxic and
                  energy efficient. We take pride in knowing our products are
                  the best tasting and made using the best sources and methods.
                </p>
              </div>
              <div className="row">
                <div class="col-md-6 mb-4 mx-auto mt-5">
                  <a href="#">
                    <div className="h-100 featured-product-box product1">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          <strong>Honey</strong>
                        </h3>
                        <p className="mb-0 text-black-50">
                          CBD Infused Honey Sticks- Ginger
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-md-6 mb-4 mt-5">
                  <a href="#">
                    <div className="h-100 featured-product-box product2">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Beverages
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Decaf Espresso Dark Roast Coffee
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product3">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Dried Fruit
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Mixed Dried Fruits
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product4">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Dropper Tinctures
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Original Dropper Tinctures
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product5">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Gummies
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Lemonade Rings
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product6">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Honey Gummies
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Cherry Cola with Organic Honey
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product7">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Honey Sticks
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          CBD Infused Honey Sticks
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product8">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Honey Tubs
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Honey Tubs- Ginger
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product9">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          Popcorn
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          Blueberry Popcorn 50mg
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 mb-4 mt-1">
                  <a href="#">
                    <div className="h-100 featured-product-box product10">
                      <div className="featured-txt">
                        <span className="featured-label text-white">
                          FEATURED
                        </span>
                        <h3 className="text-purple font-weight-bold mb-0">
                          ALL
                          <br />
                        </h3>
                        <p className="mb-0 text-black-50">
                          GO TO OUR SHOP
                          <br />
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <img className="img-fluid" src={prodisplay} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 about-left col-lg-6">
              <h1 className="text-white">
                <strong>ABOUT US</strong>
              </h1>
              <p className="text-white about-text">
                We infuse our edibles with a 100% hemp-based, proprietary blend
                oil. Our oil is extracted using subcritical (low temperature and
                pressure) CO2. This method is non-toxic and energy efficient. We
                take pride in knowing our products are the best tasting and made
                using the best sources and methods.
              </p>
              <div className="text-left mt-5 btn-container">
                <a className="button-gold" href="#">
                  <strong>READ MORE</strong>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 about-right col">
              <video
                src="https://res.cloudinary.com/db5eeugwo/video/upload/v1601527932/Heavenly_Candy_20190404_bvlopm.mp4"
                controls
              />
            </div>
          </div>
        </div>
        <div className="value-prop services-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-purple text-center">
                  <strong>SERVICES</strong>
                </h1>
                <p className="text-center text-black-50 mb-5 section-paragraph">
                  We infuse our edibles with a 100% hemp-based, proprietary
                  blend oil. Our oil is extracted using subcritical (low
                  temperature and pressure) CO2. This method is non-toxic and
                  energy efficient. We take pride in knowing our products are
                  the best tasting and made using the best sources and methods.
                </p>
              </div>
              <div className="col-md-4 text-center mb-5">
                <img className="img-fluid service-icon mb-5" src={days} />
                <h4 className="text-purple mb-5">
                  <strong>30 DAYS RETURN</strong>
                  <br />
                </h4>
                <p className="text-black-50">
                  Simply return it within 24 days for an exchange.
                </p>
                <div className="text-center mt-5 btn-container">
                  <a className="button-main" href="#">
                    <strong>RETURN POLICY</strong>
                  </a>
                </div>
              </div>
              <div className="col-md-4 text-center mb-5">
                <img className="img-fluid service-icon mb-5" src={freeship} />
                <h4 className="text-purple mb-4">
                  <strong>FREE SHIPPING </strong>
                  <br />
                  <strong>ON ALL ORDERS</strong>
                </h4>
                <p className="text-black-50">
                  Free shipping on all UK order or order above £99
                </p>
                <div className="text-center mt-5 btn-container">
                  <a className="button-main" href="#">
                    <strong>ORDER NOW</strong>
                  </a>
                </div>
              </div>
              <div className="col-md-4 text-center mb-5">
                <img className="img-fluid service-icon mb-5" src={support} />
                <h4 className="text-purple mb-4">
                  <strong>
                    DEDICATED
                    <br /> SUPPORT
                  </strong>
                </h4>
                <p className="text-black-50">
                  Contact us 24 hours a day, <br />7 days a week
                </p>
                <div className="text-center mt-5 btn-container">
                  <a className="button-main" href="#">
                    <strong>CALL US</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog blog-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-center text-purple mb-3">
                  <strong>OUR&nbsp;BLOG</strong>
                </h1>
                <p className="text-center text-black-50 mb-5 section-paragraph">
                  We infuse our edibles with a 100% hemp-based, proprietary
                  blend oil. Our oil is extracted using subcritical (low
                  temperature and pressure) CO2. This method is non-toxic and
                  energy efficient. We take pride in knowing our products are
                  the best tasting and made using the best sources and methods.
                </p>
              </div>
              <div className="col-md-8">
                <img className="img-fluid mb-3" src={blog1} />
                <h4 className="text-purple">
                  <strong>WHAT IS CBD &amp; HOW DOES IT WORK?</strong>
                </h4>
                <p className="text-black-50">
                  CBD is short for cannabidiol. It is a basic phytocannabinoid
                  found in hemp and known for supporting the body and psyche in
                  numerous ways.
                </p>
                <h6 className="text-black-50">John Joe / September 2, 2020</h6>
              </div>
              <div className="col-md-4">
                <div className="col-md-12 mb-5">
                  <img className="img-fluid mb-3" src={blog2} />
                  <h6 className="text-purple">
                    <strong>TEN BENEFITS OF CBD OIL</strong>
                    <br />
                  </h6>
                  <p className="text-black-50">
                    Cannabidiol (CBD) is one of 113 compounds found in the
                    cannabis plant and accounts for 40% of the plant's extract.
                    <br />
                  </p>
                  <h6 className="text-black-50">
                    John Joe / September 5, 2020
                  </h6>
                </div>
                <div className="col-md-12 mb-5">
                  <img className="img-fluid mb-3" src={blog3} />
                  <h6 className="text-purple">
                    <strong>CBD FOR PETS</strong>
                    <br />
                  </h6>
                  <p className="text-black-50">
                    Let’s be honest, we treat our four-legged fur babies like
                    they’re our children. It hurts to see them in pain or
                    suffering from separation anxiety.
                    <br />
                  </p>
                  <h6 className="text-black-50">
                    John Joe / September 7, 2020
                  </h6>
                </div>
              </div>
            </div>
            <div className="text-center mt-5 btn-container">
              <a className="button-main" href="#">
                <strong>VIEW&nbsp;ALL</strong>
                <br />
              </a>
            </div>
          </div>
        </div>
        <div className="reviews review-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-white text-center mb-5">
                  <strong>WHAT OUR CUSTOMERS THINK</strong>
                </h1>
              </div>

              <MySwiper slidesperview={1}>
                <div className="swiper-container simple-slider">
                  <div className="swiper-wrapper review-swiper">
                    <div className="review-box text-center">
                      <p className="text-white review-paragraph">
                        <strong>
                          Awesome place to be around like minded individuals!!
                        </strong>
                      </p>
                      <h6 className="text-gold">
                        <strong>Richard Franciskovich</strong>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="swiper-container simple-slider">
                  <div className="swiper-wrapper review-swiper">
                    <div className="review-box">
                      <p className="text-center text-white review-paragraph">
                        <strong>
                          these gummies taste great. I was a little skeptical
                          but these are worth every dollar. they taste better
                          than regular gummies in my opinion
                        </strong>
                      </p>
                      <h6 className="text-center text-gold">
                        <strong>Stephen Slyide Wood</strong>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="swiper-container simple-slider">
                  <div className="swiper-wrapper review-swiper">
                    <div className="review-box text-center">
                      <p className="text-center text-white review-paragraph">
                        <strong>
                          I love the CBD sour patch kids!! A yummy and fun way
                          to get your dose of CBD which helps my arthritis
                          immensely!!! Thank you Heavenly Candy!!
                        </strong>
                      </p>
                      <h6 className="text-center text-gold">
                        <strong>Jen Cox</strong>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="swiper-container simple-slider">
                  <div className="swiper-wrapper review-swiper">
                    <div className="review-box text-center">
                      <p className="text-center text-white review-paragraph">
                        <strong>
                          This is the absolute best CBD product on the market.
                          Not only is it the best tasting, but it leaves no
                          nasty after taste like other products on the market.
                          It has helped me tremendously with not only
                          stress/anxiety, but also my back pain and insomnia.
                        </strong>
                      </p>
                      <h6 className="text-center text-gold">
                        <strong>Andrika Chandler</strong>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="swiper-container simple-slider">
                  <div className="swiper-wrapper review-swiper">
                    <div className="review-box text-center">
                      <p className="text-center text-white review-paragraph">
                        <strong>
                          Great edible candies... licorice is yummy
                        </strong>
                      </p>
                      <h6 className="text-center text-gold">
                        <strong>Sharon Brockbank</strong>
                      </h6>
                    </div>
                  </div>
                </div>
              </MySwiper>
            </div>
          </div>
        </div>
        <div className="order section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-center text-white mb-5">
                  <strong>ORDER TODAY!</strong>
                </h1>
                <h4 className="text-white text-center order-subh mb-5">
                  We infuse our edibles with a 100% hemp-based, proprietary
                  blend oil. Our oil is extracted using subcritical (low
                  temperature and pressure) CO2. This method is non-toxic and
                  energy efficient. We take pride in knowing our products are
                  the best tasting and made using the best sources and methods.
                </h4>
                <div className="text-center btn-container mt-5">
                  <a className="button-gold" href="#">
                    <strong>ORDER&nbsp;HERE!</strong>
                    <br />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*} <div className="hero-section bg-light">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h1 className="display-4 font-weight-bold flair">
                  {siteSettings.title}
                </h1>
                <p className="lead">{siteSettings.description}</p>
              </div>
            </div>
          </Container>
        </div>
        <div className="about-section">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">
                {aboutPage.title}
              </h6>
            </div>
            <div>
              <div className="row justify-content-between align-items-center pt-5">
                <BlockContent
                  blocks={aboutPage.body || []}
                  serializers={serializers}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="services-section bg-light">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">
                Services
              </h6>
              <h2>
                <strong>How Can We Help You?</strong>
              </h2>
            </div>
            <div className="row justify-content-center pt-5">
              <div className="col-md-4 mb-5">
                <div className="p-3">
                  <LazyLoadImage src={bsns} width="100px" />
                  <h5 className="pt-4 text-uppercase font-weight-bold text-primary">
                    Lorem Ipsum
                  </h5>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="p-3">
                  <LazyLoadImage src={finance} width="100px" alt="Finance" />
                  <h5 className="pt-4 text-uppercase font-weight-bold text-primary">
                    Lorem Ipsum
                  </h5>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="p-3">
                  <LazyLoadImage src={jigsaw} width="100px" />
                  <h5 className="pt-4 text-uppercase font-weight-bold text-primary">
                    Lorem Ipsum
                  </h5>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="p-3">
                  <LazyLoadImage src={report} width="100px" />
                  <h5 className="pt-4 text-uppercase font-weight-bold text-primary">
                    Lorem Ipsum
                  </h5>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="p-3">
                  <LazyLoadImage src={trophy} width="100px" />
                  <h5 className="pt-4 text-uppercase font-weight-bold text-primary">
                    Lorem Ipsum
                  </h5>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-section">
          {/*<div className="review-nav">
            <div className="review-prev">
              <i className="fa fa-angle-left" />
            </div>
            <div className="review-next">
              <i className="fa fa-angle-right" />
            </div>
          </div>
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">Reviews</h6>
              <h2>What Our Clients Say</h2>
            </div>
          </div>
          <MySwiper slidesperview={1}>
            <div className="row justify-content-center">
              <div className="col-8 mx-auto">
                <div className="pt-5">
                  <div className="review-item text-center">
                    <div className="review-statement bg-light">
                      <p className="text-center">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                    <div className="pt-5">
                      <span className="stars">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <h4 className="mb-0">Jane Doe</h4>
                      <span className="text-muted">CEO, Company.inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 mx-auto">
                <div className="pt-5">
                  <div className="review-item text-center">
                    <div className="review-statement bg-light">
                      <p className="text-center">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                    <div className="pt-5">
                      <span className="stars">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <h4 className="mb-0">Jane Doe</h4>
                      <span className="text-muted">CEO, Company.inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 mx-auto">
                <div className="pt-5">
                  <div className="review-item text-center">
                    <div className="review-statement bg-light">
                      <p className="text-center">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                    <div className="pt-5">
                      <span className="stars">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <h4 className="mb-0">Jane Doe</h4>
                      <span className="text-muted">CEO, Company.inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MySwiper>
        </div>
        <div className="blog-section bg-light">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">blog</h6>
              <h2>Latest Posts</h2>
            </div>
            <div className="row justify-content-around py-5">
              {posts.map(post => (
                <div className="col-md-6 col-lg-4 mb-4" key={post.node.id}>
                  <div className="blog-item bg-white h-100">
                    <Link to={post.node.slug.current}>
                      <div
                        className="blog-image"
                        style={{
                          backgroundImage: `url(${
                            post.node.mainImage !== null
                              ? post.node.mainImage.asset.fluid.src
                              : "https://source.unsplash.com/user/joshhild/500x500"
                          })`,
                        }}
                      />
                    </Link>
                    <div className="blog-text">
                      <Link to={post.node.slug.current}>
                        <h4>{post.node.title}</h4>
                      </Link>
                      <p className="pt-2 text-muted">{post.node.excerpt}</p>
                      <span className="text-muted small">
                        <i className="fa fa-calendar-o pr-1" />
                        {post.node.publishedAt}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <Link className="btn btn-primary" role="button" to="/blog">
                More from the Blog
              </Link>
            </div>
          </div>
        </div>
        <div className="contact-section">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-5">
                <div className="section-heading text-left">
                  <h6 className="font-weight-bold text-uppercase text-left flair">
                    Connect
                  </h6>
                  <h2>Get in Touch with Us</h2>
                  <p className="py-4">
                    Contact us using the following details below, or fill up the
                    form and we'll get back to you shortly.
                  </p>
                  <ul className="list-unstyled contact-details">
                    <li className="phone">
                      <a
                        className="link-unstyled"
                        href="tel:+1 234 5678 900"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +1 234 5678 900
                      </a>
                    </li>
                    <li className="email">
                      <a
                        className="link-unstyled"
                        href="mailto:info@yourdomain.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@yourdomain.com
                      </a>
                    </li>
                    <li className="address">
                      22 Street Name, City Name, United States 1234
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="py-4">
                  <SampleForm
                    form_name="Newsletter Form"
                    form_id="5bcd42f86b63453b251972bc"
                    form_classname="form-newsletter"
                  >
                    <div className="form-row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="firstName"
                            required=""
                          />
                          <label htmlFor="firstName">First Name</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="lastName"
                            required=""
                          />
                          <label htmlFor="lastName">Last Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        id="homeEmail"
                        required=""
                      />
                      <label htmlFor="homeEmail">Email address</label>
                    </div>
                    <div className="form-group">
                      <input className="form-control" id="phone" type="tel" />
                      <label htmlFor="phone">Phone (optional)</label>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control h-auto"
                        id="message"
                        required=""
                      />
                      <label htmlFor="message">Message</label>
                    </div>
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                    <div className="form-group mb-4">
                      <button className="btn btn-primary" type="submit">
                        Send Message
                      </button>
                    </div>
                  </SampleForm>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    siteSettings: sanitySiteSettings {
      title
      description
    }
    aboutPage: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
